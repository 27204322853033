@font-face {
  font-family: 'DM Serif Display';
  src: local('resources/DMSerifDisplay-Regular') format('truetype');
}

@font-face {
  font-family: 'NovaSquare';
  src: local('resources/NovaSquare-Regular') format('truetype');
}

.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}